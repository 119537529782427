import React from 'react'
import './../App.css';

function About() {
  return (
         <div id="about" style={{display:"flex", height:"100vh",
          backgroundImage:"url('./../assets/2.png')", backgroundRepeat:"no-repeat", backgroundPosition:"-10% 80%",
          flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
    <div style={{height:"100%", width:"100%", backgroundColor:"#0F0E0E89",  display:"flex", justifyContent:"center", alignItems:'center',  flexDirection:"column", }}>
        <p id="title" >Hello,<br/>
We are a <span>dynamic software/app development company</span> specializing in crafting captivating web-based experiences, mobile apps and  
platforms. With a passion for <span>creativity</span> and <span>innovation</span>, offering an 
immersive experience that <span>engages, entertains, and thrills users</span>. 
</p>
 
<p id="title"  style={{fontSize:32}}>Our team is <span>committed</span> to turning ideas into <span>interactive realities</span>,
 making us your ultimate destination for your <span>ideas.</span></p>
</div>
        </div>
 
  )
}

export default About