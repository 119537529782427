import React from 'react'

function Gallery() {
  return (
    <div id="gallery" style={{ height:"100vh", display:"flex", justifyContent:"center", alignItems:'center', 
    backgroundImage:"url('./../assets/o.png')", backgroundRepeat:"no-repeat",  backgroundPosition:"-20% 50%"}}>
    <div style={{width:"100%", height:"100%", background: "rgba(15, 14, 14, 0.57)"}}>
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/2YpZiD0AIDg?si=9SZ30C9r65njDcXw&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    </div>
  )
}

export default Gallery