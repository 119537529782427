import React from 'react'
import './../App.css';

function Contact() {
  return (
    <div id="contact" style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:'center', 
    backgroundImage:"url('./../assets/4.png')", backgroundRepeat:"no-repeat", backgroundPosition:"-20% 50%"}}>
    <div style={{display:"flex", height:"100%", width:"100%", backgroundColor:"#0F0E0E89", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
 
<p id="title">Until that <span>idea</span> is built, it's just an idea. We are here to make sure that idea becomes a <span>reality</span>.</p>
<p id="title">Remember not everything needs to be an app, some solutions are simpler than you think.</p>

<p id="title">Send us an <span>email</span> or give us a <span>call</span>. We will be waiting.</p>
 
{/* <div style={{display:"flex", justifyContent:"space-around", width:"100%"}}>
<a href='mailto:nextradium@gmail.com'><img    src='./assets/email.svg' style={{width:196}} alt="imagine" /></a>
<a href='tel:+26076492828'><img    src='./assets/phone.svg' style={{width:196}} alt="imagine" /></a>
</div> */}
    </div>

</div>
  )
}

export default Contact